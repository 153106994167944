var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('WorkScheduleFilter', {
    on: {
      "refetch-data-with-filters": _vm.refetchData
    }
  }), _c('b-card', {
    staticClass: "mt-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "light",
      "opacity": 0.85
    }
  }, [_c('b-table', {
    ref: "refScheduleListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": "",
      "items": _vm.fetchSchedules,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noRecordFund'),
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "no-border-collapse": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("schedule.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(employee)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center gap-1"
        }, [!_vm.scheduleType ? _c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "11",
            "height": "11",
            "viewBox": "0 0 11 11",
            "fill": "none"
          }
        }, [_c('circle', {
          attrs: {
            "cx": "5.5",
            "cy": "5.5",
            "r": "5.5",
            "fill": "#8FD00A"
          }
        })]) : _c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "11",
            "height": "11",
            "viewBox": "0 0 11 11",
            "fill": "none"
          }
        }, [_c('circle', {
          attrs: {
            "cx": "5.5",
            "cy": "5.5",
            "r": "5.5",
            "fill": "#999"
          }
        })]), _c('span', {
          staticClass: "text-xl text-nowrap"
        }, [_vm._v(" " + _vm._s("".concat(_vm.employeeType ? 'Sales' : 'Booker', " ").concat(_vm.replaceEmployeeName(item.employee))) + " ")]), _c('span', {
          staticClass: "border-1"
        }), _c('span', {
          staticClass: "text-nowrap",
          staticStyle: {
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(item.employee.lastName) + " " + _vm._s(item.employee.firstName) + " ")])])])];
      }
    }, {
      key: "cell(scheduleCalendarTimes)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex flex-column gap-2"
        }, _vm._l(item.scheduleCalendars, function (time) {
          return _c('div', {
            key: time.id,
            staticClass: "d-flex gap-1"
          }, _vm._l(time.scheduleCalendarTimes, function (ele, index) {
            return _c('b-button', {
              key: index,
              staticStyle: {
                "font-size": "18px"
              },
              attrs: {
                "variant": "outline-dark",
                "size": "sm"
              }
            }, [_vm._v(" " + _vm._s(ele.startTime) + " - " + _vm._s(ele.endTime) + " ")]);
          }), 1);
        }), 0)];
      }
    }, {
      key: "cell(type)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.employee.type === 'BE' && item.employee.bookerType ? _c('b-badge', {
          staticClass: "badge-type",
          attrs: {
            "variant": item.employee.bookerType === 'INLAND' ? 'info' : item.employee.bookerType === 'INTERNATIONAL' ? 'warning' : item.employee.bookerType === 'BOOK_GROUP' ? 'success' : 'danger'
          }
        }, [_vm._v(" " + _vm._s(_vm.$te("schedule.".concat(item.employee.bookerType)) ? _vm.$t("schedule.".concat(item.employee.bookerType)) : item.employee.bookerType) + " ")]) : _c('div', {
          staticClass: "text-danger"
        }, _vm._l(item.scheduleTags, function (tag) {
          return _c('div', {
            key: tag.id
          }, [_vm._v(" " + _vm._s(tag.content) + " ")]);
        }), 0)];
      }
    }, {
      key: "cell(employeePhoneNumber)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('a', {
          attrs: {
            "href": "https://zalo.me/".concat(item.employee.phoneNumber),
            "target": "_blank",
            "rel": "no"
          }
        }, [_c('h3', {
          staticClass: "font-weight-bold text-info"
        }, [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": "PhoneIcon"
          }
        }), _vm._v(" " + _vm._s(item.employee.phoneNumber || 'N/A') + " ")], 1)])];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-sm-start",
    attrs: {
      "cols": "12",
      "sm": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(_vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " "), _c('span', {
    staticClass: "d-inline-block d-sm-none d-md-inline"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.outOf')) + " ")]), _c('span', {
    staticClass: "d-none d-sm-inline d-md-none"
  }, [_vm._v(" / ")]), _vm._v(" " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')))])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalSchedules,
      "per-page": _vm.perPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }